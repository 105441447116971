import React from 'react';
import { createRoot } from 'react-dom/client';
// import ReactDOM from 'react-dom';
import App from './App';
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyC7A3NnyZdlwJqQe23UbvRajZ8UdYK473U",
  authDomain: "chedagaming-7f9c2.firebaseapp.com",
  projectId: "chedagaming-7f9c2",
  storageBucket: "chedagaming-7f9c2.appspot.com",
  messagingSenderId: "574194172230",
  appId: "1:574194172230:web:548d00a1e06eb404a56547",
  measurementId: "G-G1TVHCT4RY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// Before
// ReactDOM.render(<App />, document.getElementById('root'));

// After
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(<App />);

