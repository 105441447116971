import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import '../components/FeaturedGamesSection.css'

function FeaturedGameSection() {
    const [isZoomedCod, setIsZoomedCod] = useState(false);
    const [isZoomedNba, setIsZoomedNba] = useState(false);
    const [isZoomedTekken, setIsZoomedTekken] = useState(false);
  
    const handleZoomCod = () => {
      setIsZoomedCod(!isZoomedCod);
    }
    const handleZoomNba = () => {
      setIsZoomedNba(!isZoomedNba);
    }
    const handleZoomTekken = () => {
      setIsZoomedTekken(!isZoomedTekken);
    }

  return (
    <div className="featured-games-section">
          <div className="games-section">
            <div className="games-header">
            <div className="red-bar"></div>
            <h2>Featured games</h2>            
            </div>

            <div className="games-type-container">
              <ul className="games-type-section">
                <li className="games-type-item">
                <img 
                className={`call-of-duty-games-section ${isZoomedCod ? 'zoomed' : ''}`} 
                src="https://www.callofduty.com/content/dam/atvi/callofduty/cod-touchui/blog/hero/mwiii/MWIII-REVEAL-FULL-TOUT.jpg" 
                alt="Call Of Duty"
                onClick={handleZoomCod} 
                />
                </li>

                <li className="games-type-item">
                <img 
                className={`nba-games-section ${isZoomedNba ? 'zoomed' : ''}`}
                src="https://assets.2k.com/1a6ngf98576c/5lvjCUPKs5vj2aVyzDobJh/ae2575e0e37c5e84d2e4b49978974384/N24-WEB-HOMEPAGE-RETAIL_CAROUSEL-KOBE_BRYANT_EDITION-MODULE_2-425x535-R2.jpg" 
                alt="NBA"
                onClick={handleZoomNba} 
                />
                </li>

                <li className="games-type-item">
                <img 
                className={`tekken-games-section ${isZoomedTekken ? 'zoomed' : ''}`}
                src="https://cdn.cloudflare.steamstatic.com/steam/apps/1778820/header.jpg?t=1692957440  a" 
                alt="Tekken"
                onClick={handleZoomTekken} 
                />
                </li>

              </ul>

              <Link to='/games' className="all-games-container">
              <img src="./images/icons8-game-pad.png" alt="gamepad"/>
              <h4>See All Games</h4>
              </Link>

            </div>

          </div>

        </div>
  )
}

export default FeaturedGameSection
