import React from "react";
import './DashboardPage.css';
import './BrowseGames.css';
import { Link } from "react-router-dom";

function DashboardPage () {
  
  return (
    <>
    <div className="joined__tournament__container">
            <img src="../images/2096366.jpg" alt="" />
            <div className="game-details-wrapper">  
              <div className="game-text-container">
                <div className="new">
                  <p>NEW</p>
                </div>
                <div className="text__wrapper">
                  <h2>2024 TOURNAMENT</h2>
                  <p>Built specifically to connect gamers 
                    all across Africa. Reimagining how gamers hosts, 
                    stream, play and earn from online tournaments.</p>
                </div>
                <h2 className="sub_title">ENJOY THE COMPETITIVE EXPERIENCE</h2>
                  <Link to='/tournaments#join-tournaments-section' className="view__link">
                    View
                  </Link>
              </div>
            </div>
    </div>
    <div className="browse__games__section">
      <div className="title__header">
            <div className="games__title">
                <h3>Best Selling Games</h3>
                <div class="arrow-right">
                <i class="fas fa-chevron-right"></i>
                </div>
            </div>
            <Link to='/games' className="view__all__link">
            <div className="view__all">
                <h4>View all</h4>
                <div class="arrow-right">
                <i class="fas fa-arrow-right"></i>
                </div>
            </div>
            </Link>
      </div>
      <ul className="games__section">
            <li>
            <img src="../images/bsg_cod.png" alt="" /> 
            <p>Call Of Duty</p>
            </li>

            <li>
            <img src="../images/bsg_nba2k.png" alt="" /> 
            <p>NBA 2K</p>
            </li>

            <li>
            <img src="../images/bsg_tekken.png" alt="" /> 
            <p>Tekken</p>
            </li>

      </ul>

      <Link to='/games' className="view__all__mobile__link">
            <div className="view__all__mobile">
                <h4>View all</h4>
                <div class="arrow-right">
                <i class="fas fa-arrow-right"></i>
                </div>
            </div>
      </Link>
    </div>
    </>

  );
}

export default DashboardPage;
