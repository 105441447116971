import React from 'react'
import '../components/TournamentsHeroSection.css'

function TournamentsHeroSection() {
  return (
    <div className="tournaments-hero-section">
          <div className="tournaments-hero-title">
            <div className="main-heading">
            <div className="tournaments-main-logo">
              <img src="./images/tournaments-logo.png" alt="Cheda Gaming Logo"/>
            </div>
            <div className="hero-title-para">
              <h1>2024 TOURNAMENT</h1>
              <p>Enjoy the competitive experience in the cheda gaming tournaments</p>
            </div>
            </div>
            <div >
            <a href="#join-tournaments-section" className="host-tournaments-link" >
            <button className="host-tournaments-btn">live tournaments</button>
            </a>
            </div>            
          </div> 
        </div>
  )
}

export default TournamentsHeroSection
