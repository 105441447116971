import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PaymentPage.css';
import HomeNavbar from '../components/HomeNavbar';
import Footer from '../components/Footer';

function ThankYouPage() {
  useEffect(() =>{
    document.title='Thank you for joining the tournament'
  }, []);
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  const navigate = useNavigate();
  
  useEffect(() => {
      if (!isAuthenticated) {
        navigate('/login');
      }
  }, [isAuthenticated, navigate]);  

  return (
    <>
    <HomeNavbar />
    <div className="thank-hero-section">
        <div className="payment-hero-title">
            <div className="payment-title-para">
              <h1>Thank you for joining the tournament</h1>
              <p>Check for tournament details on your dashboard</p>
            </div>
            <div >
            <a href="/user-dashboard" className="host-tournaments-link" >
            <button className="host-tournaments-btn">Dashboard</button>
            </a>
            </div>     
        </div>
    </div>
    <Footer />    
    </>

  )
  
}
export default ThankYouPage;
