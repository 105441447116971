export const MenuItems = [
    {
      title: 'Create a free tournament',
      desc: 'Got a game with friends? Lets set up a tournament and invite them!',
      img: <img src="./images/drop_img.png"/>,
      path: '/create-tournament',
      cName: 'dropdown-link'
    },
    {
      title: 'Create a paid tournament',
      desc: 'Compete with other gamers, and earn when you win.',
      img: <img src="./images/drop_img.png"/>,
      path: '/create-tournament',
      cName: 'dropdown-link'
    }
  ];