import React from "react";
import './HowItWorksSection.css'

export default function HowItWorksSection() {
  return (
    <div className="how-it-works-container">
    <img className='img-works' src='./images/how-it-works-bck-img.png'/>
    <div className="content-container">
    <div className="how-it-works-title">
      <h2>
      Enjoy your game, we will handle the rest
      </h2>
      <p>
      Cheda Gaming gives you access to wide range of gamers across Africa, 
      and it also fit your Friday night game with friends.
      </p>
      <hr/>
    </div>
    
    <div >
      <ul className="how-it-works">
        <li className="how-it-works-items">
          <h3>Signup</h3>
          <p>Gamers Signup</p>
          <p>Hosts Signup</p>
          </li>
        <li className="how-it-works-items">
          <h3>Create Tournaments</h3>
          <p>Free Tournaments</p>
          <p>Paid Tournaments</p>
        </li>
        <li className="how-it-works-items">
          <h3>Join Tournaments</h3>
          <p>Free Tournaments</p>
          <p>Paid Tournaments</p>
        </li>
      </ul>
    </div>

    </div>

    </div>
  )
}
