import React from 'react';
import Footer from '../components/Footer';
import HomeNavbar from '../components/HomeNavbar';
import GamesDetails from './GamesDetails';
import './Games.css';

function Games() {
  return (
    <div className="main">
      <HomeNavbar />
      <div className="content-wrapper">
        <GamesDetails />
      </div>
      <Footer />
    </div>

  );
}

export default Games;
