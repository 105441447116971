import React, {useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import DashboardNavbar from '../components/DashboardNavbar'
import './JoinedTournaments.css';
import JoinedTournamentsComponents from "../components/JoinedTournamentsComponents";

const JoinedTournaments = () => {
    useEffect(() =>{
        document.title='Joined Tournaments'
      }, []);

    const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!isAuthenticated) {
          navigate('/login');
        }
    }, [isAuthenticated, navigate]);  
    
     const joinedTournaments = []; 

  

  return (
    <div className="dashboard-body">
        <DashboardNavbar/>
        <Sidebar />
        <JoinedTournamentsComponents joinedTournaments={joinedTournaments} />

    </div>

  );
}

export default JoinedTournaments;
