import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import './TournamentRegistration.css';
import HomeNavbar from '../components/HomeNavbar'
import Footer from '../components/Footer';
import { createTournament, getAllUsers } from "../services/service";

function TournamentRegistration() {

  useEffect(() => {
    document.title = 'Create New Tournament';
    fetchUsers();
  }, [])
  
  const user = JSON.parse(localStorage.getItem("user"))

const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
const navigate = useNavigate();

useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
}, [isAuthenticated, navigate]);  

const gameDefaultImages = {
  "FIFA": "https://media.contentapi.ea.com/content/dam/ea/fc/fc-24/common/gameplay/fc24-pre-order-founders.jpg.adapt.1456w.jpg",
  "NBA 2K": "https://assets.2k.com/1a6ngf98576c/5lvjCUPKs5vj2aVyzDobJh/ae2575e0e37c5e84d2e4b49978974384/N24-WEB-HOMEPAGE-RETAIL_CAROUSEL-KOBE_BRYANT_EDITION-MODULE_2-425x535-R2.jpg",
  "Tekken": "https://cdn.cloudflare.steamstatic.com/steam/apps/1778820/header.jpg?t=1692957440  a",
  "Call Of Duty": "https://www.callofduty.com/content/dam/atvi/callofduty/cod-touchui/blog/hero/mwiii/MWIII-REVEAL-FULL-TOUT.jpg",
  "Street Fighter": "https://www.ea.com/games/ea-sports-fc/fc-24/images/eyJrZXkiOiJwaGFzZS0yL2dhbWVzL2VhLXNwb3J0cy1mYy9mYy0yNC9fbmV4dC9zdGF0aWMvbWVkaWEvZ2FtZS1tb2Rlcy1jbHVicy4wYmY1ZGM5ZC5wbmciLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjEyODB9LCJ3ZWJwIjp7InF1YWxpdHkiOjkwLCJlZmZvcnQiOjV9fX0=",
  "PUBG": "https://www.ea.com/games/ea-sports-fc/fc-24/images/eyJrZXkiOiJwaGFzZS0yL2dhbWVzL2VhLXNwb3J0cy1mYy9mYy0yNC9fbmV4dC9zdGF0aWMvbWVkaWEvZ2FtZS1tb2Rlcy1jbHVicy4wYmY1ZGM5ZC5wbmciLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjEyODB9LCJ3ZWJwIjp7InF1YWxpdHkiOjkwLCJlZmZvcnQiOjV9fX0="
};


  //Section I
  const defaultImage = "https://images.pexels.com/photos/6759163/pexels-photo-6759163.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
  const [section, setSection] = useState(1);
  const [hostAccount, setHostAccount] = useState(user?.user.username);
  const [tournamentName, setTournamentName] = useState("");
  const [tournamentPhoneNo, setTournamentPhoneNo] = useState("");
  const [tournamentURL, setTournamentURL] = useState("");
  const [description, setDescription] = useState("");
  const [tournamentImage, setTournamentImage] = useState(defaultImage);
  const [selectedGame, setSelectedGame] = useState('Call Of Duty');
  const [selectedGameImage, setSelectedGameImage] = useState(gameDefaultImages['Call Of Duty']); 

  // Section II
  const [participantsEmails, setParticipantsEmails] = useState([]);
  const [isFree, setIsFree] = useState(true);
  const [fee, setFee] = useState("");
  const [prize, setPrize] = useState("");
  const [paymentDetails, setPaymentDetails] = useState("");
  const [location, setLocation] = useState("")
  const [onlineLocation, setOnlineLocation] = useState(true)
  const [startDateTime, setStartDateTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  //Section I & II 
  const handleHostAccount = (e) => {
    setHostAccount(e.target.value);
  };
  const handleTournamentNameChange = (e) => {
    setTournamentName(e.target.value);
  };

  const handleTournamentPhoneNoChange = (e) => {
    setTournamentPhoneNo(e.target.value);
  };
  const handleTournamentURLInputChange = (e) => {
    setTournamentURL(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handleImageUpload = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setTournamentImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setTournamentImage(defaultImage);
    }
  };
  const handleGameChange = (e) => {
    const selectedGame = e.target.value;
    setSelectedGame(selectedGame);
    setSelectedGameImage(gameDefaultImages[selectedGame]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSection(2);
  };

  // Section II
  const handleParticipantsEmailChange = (e) => {
    const newParticipantsEmails = e.target.value.split(",").map((email) => email.trim());
    setParticipantsEmails(newParticipantsEmails);
  };
  const handleFeeRadioChange = (e) => {
    if (e.target.value === "free") {
      setIsFree(true);
    } else {
      setIsFree(false);
    }
  };
  const handleFeeChange = (e) => {
    setFee(e.target.value);
  };
  const handlePrizeChange = (e) => {
    setPrize(e.target.value);
  };

  const handlePaymentDetailsChange = (e) => {
    setPaymentDetails(e.target.value);
  };

  const handleLocationRadioChange = (e) => {
    if (e.target.value === "online") {
      setOnlineLocation(true);
    } else {
      setOnlineLocation(false);
    }
  };

  const handlePhysicalLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handleStartDateTimeChange = (e) => {
    setStartDateTime(e.target.value);
  };

  const fetchUsers = async () => {
    const res = await getAllUsers();
    setUsers(res.payload);
  }

  const handlePrevious = () => {
    setSection(1);
  }

  const handleFinalSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!isFree && parseFloat(prize) < parseFloat(fee) * 3) {
          setLoading(false);
          alert("Prize money must be at least three times the registration fee.");
          return;
    }

    const formData = {
      hostAccount: hostAccount,
      tournamentName: tournamentName,
      url: tournamentURL,
      description: description,
      hostPhoneNo: tournamentPhoneNo,
      image: tournamentImage,
      selectedGameImage: selectedGameImage,
      game: selectedGame,
      participants: participantsEmails,
      isFree: isFree,
      fee: fee,
      prize: prize,
      paymentDetails: paymentDetails,
      onlineLocation: onlineLocation,
      location: location,
      startDateTime: startDateTime
    };
    console.log(formData);
    const res = await createTournament(formData);
    if (res?.status === "success") {
      navigate('/tournaments');
    }
    setLoading(false)
  };

  return (
    <>
      <HomeNavbar />
      <div className='tournament-container'>
        <div className="tournament-box">
          <h1 className="section-one-title">{section === 1 ? "NEW TOURNAMENT" : "REGISTRATION"}</h1>
          {section === 1 && (
            <form className="tournaments-form" onSubmit={handleSubmit}>
              <div className="tournament-form-box">
                <label htmlFor="host-account">Host account:</label>
                <div className="host-placeholder tournaments-name">
                  <p
                    className="form-input-box"
                    id="host-account"
                    type="text"
                    value={hostAccount}
                    onChange={handleHostAccount} >

                    <option value={user?._id}>{user?.user.username} </option>

                  </p>
                </div>
              </div>

              <div className="tournament-form-box">
                <label htmlFor="tournamentName">Tournament name</label>
                <input
                  className="form-input-box tournaments-name"
                  type="text"
                  id="tournamentName"
                  placeholder="Enter your tournament name"
                  value={tournamentName}
                  onChange={handleTournamentNameChange}
                  required />
              </div>

              <div className="tournament-form-box">
                <label htmlFor="tournament-url-input">Tournament URL</label>
                <div className="host-placeholder">
                  <span className="default-url">chedagaming.com/</span>
                  <input
                    className="form-input-box"
                    type="text"
                    id="tournament-url-input"
                    placeholder=""
                    value={tournamentURL}
                    onChange={handleTournamentURLInputChange}
                    style={{ paddingLeft: "154px" }}
                    required
                  />
                </div>
              </div>

              <div className="tournament-form-box desc-form">
                <label htmlFor="description">Description</label>
                <textarea
                  className="form-input-box"
                  id="description"
                  name="description"
                  placeholder="Enter tournament description here"
                  rows="5"
                  defaultValue={description}
                  onChange={handleDescriptionChange}
                  required
                  style={{ minHeight: "153px", maxHeight: "153px", minWidth: "294px", maxWidth: "503px", paddingTop: "12px" }} />
              </div>

              <div className="tournament-form-box">
                <label htmlFor="tournamentPhoneNo">Phone number</label>
                <input
                  className="form-input-box tournaments-name"
                  type="number"
                  id="tournamentPhoneNo"
                  placeholder="Enter your phone number"
                  value={tournamentPhoneNo}
                  onChange={handleTournamentPhoneNoChange}
                  required />
              </div>

              <div className="tournament-form-box">
                <label htmlFor="tournament-image">Tournament image</label>
                <input
                  className="form-input-box image-upload"
                  type="file"
                  id="tournament-image"
                  accept="image/*"
                  onChange={handleImageUpload}
                />
              </div>

              <div className="tournament-form-box">
                <label htmlFor="game-dropdown">Select a Game</label>
                <div className="host-placeholder">
                  <select
                    className="form-input-box"
                    id="game-dropdown"
                    value={selectedGame}
                    onChange={handleGameChange} >
                    <option value="FIFA">FIFA</option>
                    <option value="NBA 2K">NBA 2K</option>
                    <option value="Tekken">Tekken</option>
                    <option value="Call Of Duty">Call Of Duty</option>
                    <option value="Street Fighter">Street Fighter</option>
                    <option value="PUBG">PUBG</option>
                  </select>
                  <i className='fas fa-caret-down games-icon' />
                </div>
              </div>
              <button className="save-continue-btn" type="submit">Save &amp; Continue</button>
            </form>
          )}

          {section === 2 && (
            <form >
              {/* <div className="tournament-form-box participants-emails">
                <label htmlFor="participantsEmails">Registration</label>
                <div className="host-placeholder">
                  <textarea
                    className="form-input-box"
                    id="participantsEmails"
                    multiple
                    name="participantsEmails"
                    placeholder="Provide a list of participants"
                    value={participantsEmails}
                    onChange={handleParticipantsEmailChange}>
                  </textarea>
                  <div className="tournament-type-desc">
                    <p>Separate emails with commas. <br />A tournament link will also be available to share with potential participants. </p>
                  </div>
                </div>
              </div> */}

              <div className="registration-fee">
                <div className="reg-fee-div">
                  <label className="reg-fee" htmlFor="registration-fee">Registration Fee</label>
                </div>
                <br />
                <div className="free-paid-container">
                  <div className="radio-container">
                    <div
                      className={`radio-button ${isFree ? "active" : ""}`}
                      onClick={() => handleFeeRadioChange({ target: { value: "free" } })}
                    >
                      <span>Free</span>
                    </div>
                    <div
                      className={`radio-button ${!isFree ? "active" : ""}`}
                      onClick={() => handleFeeRadioChange({ target: { value: "paid" } })}
                    >
                      <span>Paid</span>
                    </div>
                  </div>
                  {isFree ? null : (
                    <div className="paid-container">
                      <input
                        type="text"
                        className="form-input-box"
                        placeholder="Specify how much registration cost"
                        id="fee-amount"
                        value={fee}
                        onChange={handleFeeChange}
                      />
                      <br />
                      <input
                        type="text"
                        className="form-input-box"
                        placeholder="Specify the prize money"
                        id="prize-amount"
                        value={prize}
                        onChange={handlePrizeChange}
                      />
                      <br />
                      <input
                        type="text"
                        className="form-input-box paymentDetails"
                        placeholder="Enter your payment details"
                        id="payment-details"
                        value={paymentDetails}
                        onChange={handlePaymentDetailsChange}
                      />
                      <div className="tournament-type-desc">
                      <p>Format: Account Number | Bank Name | Account Name</p>
                    </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="registration-fee location-wrapper">
                <div className="reg-fee-div">
                  <label className="reg-fee" htmlFor="registration-fee">Tournament Location</label>
                </div>
                <br />
                <div className="free-paid-container">
                  <div className="radio-container">
                    <div
                      className={`radio-button ${onlineLocation ? "active" : ""}`}
                      onClick={() => handleLocationRadioChange({ target: { value: "online" } })}
                    >
                      <span>Online</span>
                    </div>
                    <div
                      className={`radio-button ${!onlineLocation ? "active" : ""}`}
                      onClick={() => handleLocationRadioChange({ target: { value: "physical" } })}
                    >
                      <span>Physical</span>
                    </div>
                  </div>
                  {onlineLocation ? null : (
                    <div className="paid-container location-container">
                      <input
                        type="text"
                        className="form-input-box"
                        placeholder="Kindly input your tournament locationt"
                        id="physical-location"
                        value={location}
                        onChange={handlePhysicalLocationChange}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="start-time-container">
                <label htmlFor="startDateTime">Start Time:</label>
                <input
                  className="form-input-box form-date-time"
                  type="datetime-local"
                  id="startDateTime"
                  name="startDateTime"
                  value={startDateTime}
                  onChange={handleStartDateTimeChange}
                  required
                />
              </div>

              <div className="previous-submit-btn">
                <button className="section-two-btn previous-btn" type="button" onClick={handlePrevious}>Previous</button>
                <Link className="section-two-btn">
                  <button className="section-two-btn" onClick={handleFinalSubmit} disabled={loading}>
                  {loading ? "Creating Tournament..." : "Create Tournament"}
                  </button>
                </Link>
              </div>

            </form>
          )}
        </div>
      </div >

      <Footer />

    </>

  );
}

export default TournamentRegistration;