import React, { useState, useEffect } from 'react';
import './ProfilePage.css';
import { useNavigate } from 'react-router-dom';
import HomeNavbar from '../components/HomeNavbar'
import Footer from '../components/Footer'

function ProfilePage() {
    useEffect(() =>{
        document.title='Profile'
      }, []);

      const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
      const navigate = useNavigate();
      useEffect(() => {
        if (!isAuthenticated) {
          navigate('/login');
        }
    }, [isAuthenticated, navigate]);  

    const defaultProfilePicture = './images/casual-life-3d-profile-picture-of-man-in-green-shirt-and-orange-hat.png';
    const [profilePicture, setProfilePicture] = useState(defaultProfilePicture);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [passwordUpdateSuccess, setPasswordUpdateSuccess] = useState(false);

  


  const handleProfilePictureChange = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.addEventListener('change', async (event) => {
      const selectedImage = event.target.files[0];
  
      if (selectedImage) {
        const reader = new FileReader();
        reader.onload = () => {
          setProfilePicture(reader.result);
        };
        reader.readAsDataURL(selectedImage);
      }
    });
    input.click();
  };

  // const handleUpdateProfilePicture = async () => {
  //   try {
  //     const response = await updateProfilePicture(profilePicture);
  //     // Handle success and error cases
  //   } catch (error) {
  //     // Handle error
  //   }
  // };

  const handleUpdatePassword = async () => {
    if (newPassword !== confirmNewPassword) {
      // Passwords don't match
      return;
    }

    // try {
    //   const response = await updatePassword(oldPassword, newPassword);
    //   // Handle success and error cases
    //   setPasswordUpdateSuccess(true);
    //   navigate ('/user-dashboard')
    // } catch (error) {
    //   // Handle error
    // }
  };

  return (
    <>
    <HomeNavbar/>
    <div className="profile-page-container">
      <div className="profile-picture-section">
        <img
          src={profilePicture}
          alt="Profile"
          className="profile-picture"
          onClick={handleProfilePictureChange}
        />
        <button className="host-tournaments-btn">Update Profile Picture</button>
        {/* <button onClick={handleUpdateProfilePicture} className="host-tournaments-btn">Update Profile Picture</button> */}
      </div>
      <div className="password-section">
        <h2>Change Password</h2>
        <input
          type="password"
          placeholder="Old Password"
          value={oldPassword}
          className="form-input-box"
          onChange={(e) => setOldPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="New Password"
          value={newPassword}
          className="form-input-box"
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="Confirm New Password"
          value={confirmNewPassword}
          className="form-input-box"
          onChange={(e) => setConfirmNewPassword(e.target.value)}
        />
         <button onClick={handleUpdatePassword} className="host-tournaments-btn">Update Password</button>
        {passwordUpdateSuccess && <p>Password updated successfully!</p>}
      </div>
    </div>
    <Footer/>
    </>
  );
}

export default ProfilePage;
