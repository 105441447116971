import axios from "axios";

const baseUrl = "https://api.chedagaming.com/api/v1/";

// const baseUrl = "https://localhost:5000/api/v1/";


export const SignIn = (data) => {
    return axios.post(`${baseUrl}auth/login`, data).then((res) => {
        return res.data;
    }).catch((err) => {
        return err?.response?.data;

    })
}
export const signUp = (data) => {
    return axios.post(`${baseUrl}auth/register`, data).then((res) => {
        return res.data;
    }).catch((err) => { 

        return err?.response?.data;
    })
}

export const resetPassword = (data) => {
    return axios
      .post(`${baseUrl}auth/reset-password`, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err?.response?.data;
      });
  };

export const createTournament = (data) => {
    return axios.post(`${baseUrl}tournament`, data).then((res) => {
        return res.data;
    }).catch((err) => {

        return err?.response?.data;
    })
}
export const getAllTournaments = (data) => {
    return axios.get(`${baseUrl}tournament`, data).then((res) => {
        return res.data;
    }).catch((err) => {

        return err?.response?.data;
    })
}

export const getAllUsers = (data) => {
    return axios.get(`${baseUrl}user`, data).then((res) => {
        return res.data;
    }).catch((err) => {

        return err?.response?.data;
    })
}

export const getSingleTournament = (id, data) => {
    return axios.get(`${baseUrl}tournament/${id}`, data).then((res) => {
        return res.data;
    }).catch((err) => {

        return err?.response?.data;
    })
}

export const getJoinedTournament = (id, data) => {
    return axios.get(`${baseUrl}tournament/${id}`, data).then((res) => {
        return res.data;
    }).catch((err) => {

        return err?.response?.data;
    })
}