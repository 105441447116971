import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import SignupNavbar from '../components/SignupNavbar';
import './Signup.css'
import Footer from '../components/Footer';
import { signUp } from "../services/service";


function Signup() {
  useEffect(() => {
    document.title = 'Signup'
  }, []);


  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  // const [country, setCountry] = useState("");
  const [termsOfService, setTermsOfService] = useState(false);
  const [emailUpdates, setEmailUpdates] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  async function createAccount() {
    setError("");
    setLoading(true);

    let data = {
      username,
      email,
      password,
      phone,
      // country,
    }

    try {
      if (password !== passwordConfirmation) {
        setError('Password and confirm password do not match');
        setLoading(false); // Reset loading state
        return;
      }

      // const data = {
      //   username,
      //   email,
      //   password,
      //   country,
      // };

      const res = await signUp(data);

      if (res?.status === "success") {
        localStorage.setItem("isAuthenticated", "true");
        localStorage.setItem("user", JSON.stringify(res?.payload));
        navigate('/login');
      } else {
        setError('Account creation failed. Please try again later.');
      }
    } catch (e) {
      setError('An error occurred while creating your account. Please try again later.');
    } finally {
      setLoading(false); 
    }
  }


  return (
    <>
      <SignupNavbar />
      <div className='signup-body'>
        <div className='signup-main-section'>
          <div className='signup-box'>
            <div className='signup-title'>
              <h1>Sign up to Cheda Gaming</h1>
              {/* <p>Welcome to our community! Please enter your details or
                Sign up with your social media account.</p> */}
                <p>Welcome to our community! Please enter your details.</p>
            </div>
            {/* <div className='social-media-signup'>
              <h3>Sign up with</h3>
              <ul >
                <li className='signup-social-icon'>
                  <Link to=''>
                    <img src="./images/Google.png" alt="Google Logo" />
                  </Link>
                  <Link to=''>
                    <img src="./images/Facebook.png" alt="Facebook Logo" />
                  </Link>
                  <Link to=''>
                    <img src="./images/Discord.png" alt="Discord Logo" />
                  </Link>
                </li>
              </ul>
            </div>
            <hr className='signup-form-or-line' /> */}
    
            <div >
              <div className="signup-user-box">
                <input id="username"
                  name="username"
                  type="text"
                  placeholder="Username/Gaming Tag"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required />
              </div>

              <div className="signup-user-box">
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required />
              </div>
              <div className="signup-user-box">
                <input
                  id="phone"
                  name="phone"
                  type="tel"
                  placeholder="Phone number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required />
              </div>
              <div className="signup-user-box">
                <input
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />

              </div>
              <div className="signup-user-box">
                <input
                  name="confirmPwd"
                  type="password"
                  placeholder="Confirm Password"
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                  required
                />
              </div>
              {/* <div className="signup-user-box">
                <select value={country} onChange={(e) => setCountry(e.target.value)}>
                  <option value="">Select a country</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Tanzanian">Tanzanian</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Uganda">Uganda</option>
                </select>
              </div> */}

              <hr className='pass-form-line' />

              <div className='terms-policy-container'>
                <input
                  id="terms-privacy-checkbox"
                  name="terms-privacy-checkbox"
                  type="checkbox"
                  checked={termsOfService}
                  onChange={(e) => setTermsOfService(e.target.checked)}
                />
                <label className='terms-privacy-checkbox' htmlFor="terms-privacy-checkbox"> I agree to the &nbsp;
                  <Link to='/' className='terms-policy'>
                    Terms of Service
                  </Link>
                  &nbsp; and &nbsp;
                  <Link to='/' className='terms-policy'>
                    Privacy Policy.
                  </Link></label>
              </div>

              <div className='newsletter-container'>
                <input id="newsletter-opt-in" name="newsletter-opt-in"
                  type="checkbox"
                  checked={emailUpdates}
                  onChange={(e) => setEmailUpdates(e.target.checked)}
                />
                <label className='newsletter-opt-in' htmlFor="newsletter-opt-in">
                  Send me occasional product updates and info about major tournaments.</label>
              </div>
              <hr className='signup-form-line' />
              <button className="signup-btn" onClick={createAccount} disabled={loading}>
              {loading ? "Creating your account..." : "CREATE AN ACCOUNT"}
              </button>
              {error && <p className="span-text">{error}</p>}
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </>


  )
}

export default Signup
