import React, { useEffect, useState } from 'react';
import '../pages/TournamentDetails.css'
import { GrGallery, GrSubtract } from 'react-icons/gr';
import { ImLink } from 'react-icons/im';
import { Link, useParams, useNavigate} from 'react-router-dom';
import { getSingleTournament, getAllUsers } from '../services/service';
import moment from 'moment';



function TournamentDetailsSection() {

  const user = JSON.parse(localStorage.getItem("user"))
  const { tournamentsID } = useParams();
  const [tournament, setTournamentData] = useState({});
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {

    const fetchTournament = async () => {
      try {
        setLoading(true);
        const res = await getSingleTournament(tournamentsID);
        setTournamentData(res?.payload);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching tournament:', error);
        setLoading(false);
      }
    };

    fetchTournament();
    fetchUsers();

  }, [])

  const fetchUsers = async () => {
    const res = await getAllUsers();
    setUsers(res.payload);
  }

  const [showMessage, setShowMessage] = useState(false);
  const isLoggedIn = localStorage.getItem("isAuthenticated");
  const handlePermalinkClick = () => {
    const permalink = window.location.href;
    navigator.clipboard.writeText(permalink)
      .then(() => {
        console.log('Permalink copied to clipboard:', permalink);
      })
      .catch((error) => {
        console.error('Failed to copy permalink:', error);
      });

    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 2000);
  };

  const handleImageOnclick = () => {
    navigate ('/games')
  };
    

  return (
      <div className="tournament-info">
        <div className="main_tournament_details_container">
        <div className="first__section">
        <div className="container-wrap">
            <div className="item-container">
            <div className="more-like-this-item">
              <GrGallery className="icon_tournament" />
              <Link to='/tournaments' className="more-item-link">
                <span className="span-text">More like this</span>
              </Link>
            </div>
            <div><GrSubtract className="dash icon_tournament" /></div>
            <div className="permalink-item" onClick={handlePermalinkClick}>
              <ImLink className="icon_tournament" />
              <span className="span-text">Share tournament</span>
            </div><br/>
            {showMessage && <p className="message">Tournament link has been copied successfully</p>}
          </div>

          <div className="title-wrapper">            
            <h1>{tournament.tournamentName}</h1>
            <div className="first-item-container">
                <h4 className="first-item-h">{moment(tournament.startDateTime).format("MMMM D, YYYY h:mm A")}</h4>
                <div><GrSubtract className="dash icon" /></div>
                <div>
                {tournament.onlineLocation ? (
                      <div className="location-container-wrapper">
                        <h4 className="first-item-h">Location:</h4> 
                        <p>Online</p></div>
                      ) : (
                        <div className="location-container-wrapper">
                        <h4 className="first-item-h">Location:</h4> 
                        <p>Physical</p>
                        </div>
                      )}

                </div>
                <div><GrSubtract className="dash icon" /></div>
                <div>
                    {tournament.isFree ? (
                      <h4 className="first-item-h">Free Entry</h4>
                      ) : (
                        <div className="location-container-wrapper">
                        <h4 className="first-item-h">Reg Fee:</h4>
                        <p>KSH {tournament.fee}</p>    
                        </div>
                      )}                      
                </div>
            </div> 
            <p>{tournament.description}</p>
            <div className="first-item-container">
                <div className="location-container-wrapper">
                <h4 className="first-item-h">Phone:</h4>
                <p>{tournament.hostPhoneNo}</p></div>
            <div className="location_div">
                {tournament.onlineLocation ? (                                            
                        <p></p>
                      ) : (
                        <div className="location-container-wrapper">
                        <div className="dash-div"><GrSubtract className="dash icon" /></div>
                        <h4 className="first-item-h">Address:</h4>  
                        <p>{tournament.location}</p>
                        </div>
                )}
            </div>   
            </div>   
            <div>
            {
             tournament.prize && <div className="prize-wrapper">
               <h2>Prize money:</h2>
               <h2>KSH {tournament.prize}</h2>
             </div>
            }
          </div>
                <div>
                    {tournament.isFree ? (
                      <h4 className="first-item-h"></h4>
                      ) : (
                        <div className="location-container-wrapper">
                        <h4 className="payment-details first-item-h">{tournament.paymentDetails}</h4>    
                        </div>
                      )}                      
                </div>

            <Link to="https://wa.link/rtr5si">
            <button className="join-tournaments-btn">
              Join Tournament
            </button>
            </Link>
            </div> 

          <div className="tournament-img-container mobile-img">
               <img className="tournament-image" src={tournament.image}/>
          </div>

        <div className="games__list mobile-img">
          <h4 className="featured-text span-text">Featured Games</h4>   
          <ul>
            <li>
                <iframe src="https://www.youtube.com/embed/tv7LfFeamsc" frameborder="0" allowFullScreen></iframe>
            </li>
            <li>
                <iframe src="https://www.youtube.com/embed/GITzbGIiNKg" frameborder="0" allowFullScreen></iframe>
            </li>
            <li>
                <iframe src="https://www.youtube.com/embed/XhP3Xh4LMA8" frameborder="0" allowFullScreen></iframe>
            </li>
        </ul>
        </div>

          
        </div>  

        <div className="games__list desktop-img">
        <h4 className="featured-text span-text">Featured Games</h4>   
        <ul>
            <li>
                <iframe src="https://www.youtube.com/embed/tv7LfFeamsc" frameborder="0" allowFullScreen></iframe>
            </li>
            <li>
                <iframe src="https://www.youtube.com/embed/GITzbGIiNKg" frameborder="0" allowFullScreen></iframe>
            </li>
            <li>
                <iframe src="https://www.youtube.com/embed/XhP3Xh4LMA8" frameborder="0" allowFullScreen></iframe>
            </li>
        </ul>

          <div className="final-btn">
            <div>
            <Link to="/tournaments">
            <button className="join-tournaments-btn">
              Live Tournaments
            </button>
            </Link>
            </div>

            <Link to="/create-tournament">
            <button  className="live-btn join-tournaments-btn">
              Create Tournament
            </button>
            </Link>

          </div>
        </div>  

        <div className="games__list desktop-img">
        <h4 className="featured-text span-text">Featured Games</h4>   
        <ul>
            <li>
                <iframe src="https://www.youtube.com/embed/tv7LfFeamsc" frameborder="0" allowFullScreen></iframe>
            </li>
            <li>
                <iframe src="https://www.youtube.com/embed/GITzbGIiNKg" frameborder="0" allowFullScreen></iframe>
            </li>
            <li>
                <iframe src="https://www.youtube.com/embed/XhP3Xh4LMA8" frameborder="0" allowFullScreen></iframe>
            </li>
        </ul>
        </div>
        </div>
        
        <div className="tournament-img-container desktop-img">
          <img className="tournament-image" src={tournament.image}/>
        </div>

        </div>
      </div>

  );
}

export default TournamentDetailsSection;