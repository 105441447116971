import React, { useEffect } from 'react';
import {useNavigate } from 'react-router-dom';
import Footer from '../components/Footer'
import Navbar from '../components/TournamentsNavbar'
import TournamentDetailsSection from '../components/TournamentDetailsSection'



function TournamentDetails() {

  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Join Tournament'

  }, [])

  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  
  useEffect(() => {
      if (!isAuthenticated) {
        navigate('/login');
      }
  }, [isAuthenticated, navigate]);  


  return (
    <>
    <Navbar/>
    <TournamentDetailsSection/>
    <Footer />
    </>


  );
}

export default TournamentDetails;