import React from 'react'
import './AboutUs.css'

function AboutUs() {
  return (
    <div className='about-us-container'>
        <div className='about-us'>
            <img src='/images/gamers-connect.jpg' alt='a gamer playing a game on a laptop'/>
            <div className='about-us-items'>
                <div className='about-us-items-title'> 
                    <h2>
                    Building an ecosystem that connects gamers across Africa
                    </h2>
                    <hr/>
                </div>
                <div>
                    <ul className='about-us-items-list'>
                        <li className='items'>
                            <img src='/images/game-controller.png' alt='Game controller'/>
                            <div className='items-desc'>
                                <h3>
                                Play online tournaments
                                </h3>
                                <p>
                                Play free and paid tournaments on our platform.
                                </p>
                            </div>
                        </li>
                        <li className='items hosts'>
                            <img src='/images/game-tournaments.png' alt='Tournaments Icon'/>
                            <div className='items-desc'>
                                <h3>
                                Host Online Tournaments
                                </h3>
                                <p>
                                Host online tournaments via our platform, 
                                with access to a wide range of gamers cut across Africa.
                                </p>
                            </div>
                        </li>
                        <li className='items earn'>
                            <img src='/images/game-earnings.png' alt='Bag of money'/>
                            <div className='items-desc'>
                                <h3>
                                Earn From Online Tournaments
                                </h3>
                                <p>
                                An easy way where both hosts and gamers can 
                                earn from hosting and playing online tournaments.
                                </p>
                            </div>
                        </li>
                        <li className='items livestream'>
                            <img src='/images/game-livestream.png' alt='Livestreaming Icon'/>
                            <div className='items-desc'>
                                <h3>
                                Livestream Online Tournaments
                                </h3>
                                <p>
                                All e-Sport fans can stream all 
                                online tournaments hosted on our platforms.
                                </p>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </div>  
      
    </div>
  )
}

export default AboutUs
