
import React from 'react';
import { Link } from 'react-router-dom';
import './TournamentsNavbar.css';

function TournamentsNavbar() {
  const isLoggedIn = localStorage.getItem("isAuthenticated");


  return (
    <>
      <nav className="tournaments-navbar">
        <div className="tournaments-navbar-container">
          <div className="tournaments-navbar-logo">
            <Link to='/' >
              <img src="../images/chedagaming_logo.png" alt="Cheda Gaming Logo" />
            </Link>
          </div>

          <div >
            <Link className="create-tournaments-link" to={isLoggedIn ? "/create-tournament" : "/login"}>
              <button className="create-tournaments-btn">create tournament</button>
            </Link>
          </div>
        </div>
      </nav>
    </>

  );
}

export default TournamentsNavbar; 