import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LoginNavbar from '../components/LoginNavbar';
import Footer from '../components/Footer';
import { resetPassword } from '../services/service';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  async function handleResetPassword() {
    setLoading(true);

    try {
     
      const res = await resetPassword({ email });

      if (res?.status === 'success') {
        setSuccessMessage('Password reset email sent. Check your inbox.');
      } else {
        setErrorMessage('Failed to send password reset email.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while resetting the password.');
    }

    setLoading(false);
  }

  return (
    <>
      <LoginNavbar />
      <div className="login-body">
        <div className="login-main-section">
          <div className="login-box">
            <div className="login-title">
              <h1>Forgot Password</h1>
              <p>Enter your email address to reset your password.</p>
            </div>
            {successMessage && (
              <div className="success-message">{successMessage}</div>
            )}
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <div className="login-user-box">
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
            </div>
            <hr className="login-form-line" />
            <button
              className="login-btn"
              onClick={handleResetPassword}
              disabled={loading}
            >
              {loading ? 'Sending email...' : 'Reset Password'}
            </button>
            <div className="rmb-frgt">
              <Link to="/login" className="login-link">
                Back to Login
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ForgotPassword;
