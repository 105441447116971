import React, { useState, useEffect } from 'react'; 
import '../components/styles/LiveTournaments.css'
import { getAllTournaments } from '../services/service';
import moment from 'moment-timezone';
import 'moment-timezone';
import { Link } from 'react-router-dom';

function LiveTournaments() {
  const isLoggedIn = localStorage.getItem("isAuthenticated");
  
  const [loading, setLoading] = useState(false);
  const [tournaments, setTournaments] = useState([]);
  const [noTournaments, setNoTournaments] = useState(false);
  const [error, setError] = useState(null);

  const fetchTournament = async () => {
    setLoading(true);
    setError(null);

    try {
      const res = await getAllTournaments();

      if (!res || !res.payload) {
        throw new Error('Invalid response from the server');
      }

      const filteredTournaments = res.payload.filter(tournament => {
        const startDateTime = moment.tz(tournament.startDateTime, "Africa/Nairobi");
        const currentDateTime = moment().tz("Africa/Nairobi");
        return startDateTime.isAfter(currentDateTime.subtract(7, 'minutes'));
      });

      setTournaments(filteredTournaments);
      setNoTournaments(filteredTournaments.length === 0);

    } catch (err) {
      console.error('Failed to fetch tournaments:', err);
      setError('Failed to fetch tournaments. Please try again later.');
      setNoTournaments(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTournament();
  }, []);

  return (
    <div id="join-tournaments-section" className="live-tournaments-section">
      <div className="join-tournament-container">
        <div className="join-tournament-heading">
          <h1>Join live tournaments</h1>
          <p className="live_parag">Connect with amazing and competitive gamers all across Africa. Earn while doing what you love the most.</p>
        </div>
        {loading && (
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: "25px"
          }}>
            Fetching Data
          </div>
        )}
        {error && (
          <div className="error-message" style={{
            color: "red",
            textAlign: "center"
          }}>
            {error}
          </div>
        )}
        <div className="list -four">
          {noTournaments ? (
            <div className="no-tournaments">
              <p className="live_parag">No live tournaments available.</p>
              <Link className="create-tournaments-link" to={isLoggedIn ? "/create-tournament" : "/login"}>
                <button className="create-tournaments-btn">Create Tournament</button>
              </Link>
            </div>
          ) : (
            tournaments.map((tournament, idx) => (
              <div className="search-result-card" key={idx}>
                <div className="header">
                  <img src={tournament.image} alt='' />
                </div>
                <div className="body">
                  <div className="user-details">
                    <div className="img">
                      <img 
                        src={tournament.selectedGameImage}
                        alt={tournament.game}
                        className="user-avatar -smed"/>
                    </div>
                    <div className="name -break">
                      <h3><strong> {tournament.tournamentName}</strong></h3>
                    </div>
                  </div>
                  <ul className="list-details">
                    <li>
                      <i className="icon fa fa-user"></i>
                      <span className="text">{tournament.hostAccount}</span>
                    </li>
                    <li>
                      <i className="icon fa fa-gamepad"></i>
                      <span className="text">{tournament.game}</span>
                    </li>
                    <li>
                      {tournament.isFree ? (
                        <div className="text"><i className="icon fa fa-money-bill-wave"></i>Free Entry</div>
                      ) : (
                        <div className="text"><i className="icon fa fa-money-bill-wave"></i>Reg Fee: KSH {tournament.fee} |<br/><strong>Prize: KSH {tournament.prize}</strong></div>
                      )}
                    </li>
                    <li>
                      <i className="icon fa fa-calendar"></i>
                      <span className="text">{moment(tournament.startDateTime).format("MMMM D, YYYY h:mm A")}</span>
                    </li>
                    <li>
                      {tournament.onlineLocation ? (
                        <div className="text"><i className="icon fa fa-map-marker-alt"></i>Online</div>
                      ) : (
                        <div className="text"><i className="icon fa fa-map-marker-alt"></i>Physical</div>
                      )}
                    </li>
                  </ul>
                  <div className="actions">
                    <a className="btn btn-block btn-default" href={`/tournaments/${tournament._id}`}>View</a>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default LiveTournaments;
