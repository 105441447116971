import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LoginNavbar from '../components/LoginNavbar';
import './Login.css'
import Footer from '../components/Footer';
import { SignIn } from '../services/service';


function Login() {
  useEffect(() => {
    document.title = 'Login'
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState('');

  const navigate = useNavigate();

  async function logIn() {
    setLoading(true);
    let data = {
      email,
      password
    };
    try {
      const res = await SignIn(data);
      if (res?.status === "success") {
        localStorage.setItem("isAuthenticated", "true");
        localStorage.setItem("user", JSON.stringify(res?.payload))
        navigate('/tournaments');

      }

    } catch (e) {
      if (e.response && e.response.status === 401) {
        // Handle unauthorized login (email or password not found)
        setLoginError('Wrong email or password. Please try again.');
      } else {
        setLoginError(e.message);
      }
    } finally {
      setLoading(false);
    }
  }
  return (
    <>
      <LoginNavbar />
      <div className='login-body'>
        <div className='login-main-section'>
          <div className='login-box'>
            <div className='login-title'>
              <h1>Log in to Cheda Gaming</h1>
              <p>Welcome back! Please enter your details or
                log in with your social media account.</p>
            </div>
            {/* <div className='social-media-login'>
              <h3>Log in with</h3>
              <ul >
                <li className='login-social-icon'>
                  <Link to=''>
                    <img src="./images/Google.png" alt="Google Logo" />
                  </Link>
                  <Link to=''>
                    <img src="./images/Facebook.png" alt="Facebook Logo" />
                  </Link>
                  <Link to=''>
                    <img src="./images/Discord.png" alt="Discord Logo" />
                  </Link>
                </li>
              </ul>
            </div> */}
            {/* <hr className='login-form-or-line' /> */}

            <div >
              <div className="login-user-box">
              {loginError && <p className="span-text">{loginError}</p>}
                <input
                  id='email'
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  required />
              </div>
              <div className="login-user-box">
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={password} onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  required />
              </div>

              <div className='rmb-frgt'>
                <Link to='/forget-password' className='forget-password'>
                  Forgot password
                </Link>
              </div>
              <hr className='login-form-line' />
              <button className='login-btn' onClick={logIn} disabled={loading} >
              {loading ? "Signing in..." : "LOG IN"}
                </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Login
