import React from 'react';
import { Link } from 'react-router-dom';
import '../pages/JoinedTournaments.css';

function JoinedTournamentsComponents({ joinedTournaments }) {

  // if (!joinedTournaments) {
  //   // Handle the case when joinedTournaments prop is not defined or empty
  //   return <div>No joined tournaments to display.</div>;
  // }

  return (
    <div className="joined__tournament__container joined">
        <div className="game-details-wrapper">
          {joinedTournaments.length > 0 ? (
            joinedTournaments.map((tournament, index) => (
          <div className="game-text-container">
            <div className="new">
              <p>NEW</p>
            </div>
            <div className="text__wrapper">
              <h2>{tournament.tournamentName}</h2>
              <p>{tournament.description}</p>
            </div>
            <h2>{tournament.isFree ? 'Free Entry' : `Registration Fee: ${tournament.fee}`}</h2>
            <Link to={`/tournament/${tournament._id}`} className="view__link">
              View
            </Link>
          </div>
          ))
          ) : (
          <p>No joined tournaments yet.</p>
          )}
        </div>
    </div>
  );
}

export default JoinedTournamentsComponents;

